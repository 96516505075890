/* eslint-disable */
<template>
  <section class="text-gray-900 body-font relative">
    <div class="contact-us-area">
      <div class="container px-5 py-4 mx-auto">
        <div class="flex flex-wrap">
          <div class="w-full">
            <div class="text-center mb-4">
              <h2 class="text-purple-950 sm:text-5xl text-2xl font-bold">Let's Connect</h2>
              <p class="leading-relaxed text-base">Contact us for a free Assure Travel consultation.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-us-form-area lg:-mb-24 pb-0 relative">
      <div class="container px-5 py-4 mx-auto">
        <div class="flex flex-wrap">
          <div class="w-full">
            <div v-if="!formSubmitted">
            </div>
            <contact-form></contact-form>

            <transition name="fade">
								<div v-if="formSubmitted" style="transition-duration: 2s;" class="w-full md:w-10/12 mx-auto flex flex-col mb-16 justify-center items-center text-center bg-white border-2 shadow-lg rounded-lg border-gray-200 border-opacity-50 p-8 h-96">
									<h2 class="text-purple-950 sm:text-5xl text-2xl font-bold">Thanks for getting in touch!</h2>
									<p class="mb-8 text-xl">Expect an reply from an Assure Travel Consultant very soon.</p>
								</div>
						</transition>
          </div>
        </div>

      </div>
      <!-- <button id="show-modal" @click="showModal = true">Show Modal</button> -->
      <transition name="modal">
        <div id="modal-template" class="modal-mask" v-if="showModal">
          <div class="modal-wrapper">
            <div class="w-full md:w-1/2 mx-auto flex flex-col mb-16 justify-center items-center text-center bg-white border-2 shadow-lg rounded-lg border-gray-200 border-opacity-50 p-8 h-96">
              <!-- <div style="transition-duration: 2s;" class="w-full md:w-10/12 mx-auto flex flex-col mb-16 justify-center items-center text-center bg-white border-2 shadow-lg rounded-lg border-gray-200 border-opacity-50 p-8 h-96" v-if="formSubmitted">
									<h2 class="text-purple-950 sm:text-5xl text-2xl font-bold">Thanks for getting in touch!</h2>
									<p class="mb-8 text-xl">Expect an reply from an Assure Travel Consultant very soon.</p>
								</div> -->
              <div class="modal-header">
                <h2 class="text-purple-950 sm:text-5xl text-2xl font-bold">Thanks for getting in touch!</h2>
                <!-- <p class="mb-8 text-xl">Expect an reply from an Assure Travel Consultant very soon.</p> -->
              </div>

              <div class="modal-body">
                <p class="mb-8 text-xl">Expect an reply from an Assure Travel Consultant very soon.</p>
              </div>

              <div class="modal-footer">
                <slot name="footer">
                  <button class="bg-purple-950 text-white active:bg-purple-950 font-bold uppercase text-xs px-4 py-2 rounded-md shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" @click="showModal = false">
                    OK
                  </button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <div class="custom-shape-divider-middle-1624376907">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path
            d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
            class="shape-fill"></path>
        </svg>
      </div>
    </div>
  </section>
  
</template>
<script>
// @ is an alias to /src
    // import HelloWorld from "@/components/HelloWorld.vue";
    // import HeroSection from "@/components/HeroSection.vue";
    import emailjs from 'emailjs-com';
    import ContactForm from '@/components/ContactForm.vue';
    export default {
        name: "Contact",
        components: {
            ContactForm,
        },
        data() {
            return {
              showModal: false,
              formSubmitted: false,
			  number: '',
            };
        },
      methods: {
        sendEmail: (e) => {
          e.preventDefault();
          emailjs.sendForm('service_5n50m1i','template_ccsj5am', e.target, 'user_LLFN61yrORT6uCl1TucqR');
          this.hideForm();
        },
		hideForm() {
                this.formSubmitted = false;
            }
        
      },
	  watch: {
    number(){
      this.number = this.number.replace(/[^0-9]/g, '')
      .replace(/^(\d{3})(\d{3})(\d{4})/g, '($1) $2-$3');
    }
  }, 
  }
</script>
<style scoped>
/* Transition styles */
 .fade-enter-active, .fade-leave-active {
     transition: opacity 0.5s ease;
}
 .fade-enter-from, .fade-leave-to {
     opacity: 0;
}
 .modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.5);
     display: table;
     transition: opacity 0.3s ease;
}
 .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
}
 .modal-container {
     width: 300px;
     margin: 0px auto;
     padding: 20px 30px;
     background-color: #fff;
     border-radius: 2px;
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
     transition: all 0.3s ease;
     font-family: Helvetica, Arial, sans-serif;
}
 .modal-header h3 {
     margin-top: 0;
     color: #42b983;
}
 .modal-body {
     margin: 20px 0;
}
 .modal-default-button {
     float: right;
}
/* * The following styles are auto-applied to elements with * transition="modal" when their visibility is toggled * by Vue.js. * * You can easily play with the modal transition by editing * these styles. */
 .modal-enter {
     opacity: 0;
}
 .modal-leave-active {
     opacity: 0;
}
 .modal-enter .modal-container, .modal-leave-active .modal-container {
     -webkit-transform: scale(1.1);
     transform: scale(1.1);
}
 .custom-shape-divider-middle-1624376907 {
     position: absolute;
     top: 25%;
     left: 0;
     width: 100%;
     overflow: hidden;
     line-height: 0;
     transform: rotate(180deg);
     z-index: -1;
}
 .custom-shape-divider-middle-1624376907 svg {
     position: relative;
     display: block;
     width: calc(100% + 1.3px);
     height: 90px;
}
 .custom-shape-divider-middle-1624376907 .shape-fill {
     fill: #FFFFFF;
}
 .contact-us-area {
     padding-top: 85px 
}
 @media(min-width:768px) {
     .contact-us-area {
         padding-top: 113px 
    }
}
 @media(min-width:992px) {
     .contact-us-area {
         padding-top: 158px 
    }
}
 .contact-us-area .contact-us-banner {
     margin-bottom: 40px 
}
 @media(min-width:768px) {
     .contact-us-area .contact-us-banner {
         margin-bottom: 55px 
    }
}
 @media(min-width:992px) {
     .contact-us-area .contact-us-banner {
         margin-bottom: 70px 
    }
}
 .contact-us-area .contact-us-banner h2 {
     color: #1D156B;
     font-size: 36px;
     font-weight: 700;
     font-style: normal;
     letter-spacing: -1.98px;
     line-height: normal;
     margin-bottom: 10px 
}
 @media(min-width:576px) {
     .contact-us-area .contact-us-banner h2 {
         font-size: 40px 
    }
}
 @media(min-width:768px) {
     .contact-us-area .contact-us-banner h2 {
         font-size: 60px 
    }
}
 .contact-us-area .contact-us-banner p {
     color: #81838c;
     font-size: 20px;
     font-weight: 400;
     font-style: normal;
     letter-spacing: -0.66px;
     line-height: 34px 
}
 .contact-us-form-area {
     position: relative;
     padding-bottom: 60px 
}
 @media(min-width:768px) {
     .contact-us-form-area {
         padding-bottom: 80px 
    }
}
 @media(min-width:992px) {
     .contact-us-form-area {
         padding-bottom: 130px 
    }
}
 .contact-us-form-area:after {
     position: absolute;
     content: '';
     background: #1D156B;
     bottom: 0;
     left: 0;
     width: 100%;
     z-index: -2;
     height: 75% 
}
 .contact-us-form {
     box-shadow: 0 -20px 79px rgba(0, 0, 0, 0.08);
     border-radius: 15px;
     background-color: #fff;
     padding: 33px 30px 40px 30px 
}
 @media(min-width:576px) {
     .contact-us-form {
         padding: 55px 50px 55px 50px 
    }
}
 @media(min-width:768px) {
     .contact-us-form {
         padding: 55px 75px 55px 75px 
    }
}
 .contact-us-form label {
     color: #1D156B;
     font-size: 16px;
     font-weight: 500;
     font-style: normal;
     letter-spacing: -0.6px;
     line-height: normal;
     margin-bottom: 20px 
}
 .contact-us-form .form-control::-moz-placeholder {
     color: #cbd5e0;
     opacity: 1 
}
 .contact-us-form .form-control:-ms-input-placeholder {
     color: #cbd5e0;
     opacity: 1 
}
 .contact-us-form .form-control::placeholder {
     color: #cbd5e0;
     opacity: 1 
}
 .contact-us-form .form-control {
     color: #81838c;
     font-size: 16px;
     font-weight: 400;
     font-style: normal;
     letter-spacing: -0.53px;
     line-height: normal;
     height: 50px;
     border-radius: 5px;
     border: 1px solid #eaeced;
     background-color: #f7fafc;
     margin-bottom: 28px;
     padding-left: 15px 
}
 .contact-us-form textarea {
     height: 220px !important;
     padding-top: 15px 
}
 .contact-us-form .btn {
     width: 100%;
     height: 60px;
     border-radius: 6px;
     background-color: #1D156B;
     color: #fff;
     font-size: 16px;
     font-weight: 700;
     font-style: normal;
     letter-spacing: -0.53px;
     line-height: normal;
     text-align: center 
}
 .contact-get-in-touch-area {
     margin-bottom: -30px;
     padding-top: 60px 
}
 @media(min-width:768px) {
     .contact-get-in-touch-area {
         padding-top: 80px 
    }
}
 @media(min-width:992px) {
     .contact-get-in-touch-area {
         padding-top: 120px 
    }
}
 .contact-get-in-touch-area .get-in-touch-single {
     margin-bottom: 30px 
}
 .contact-get-in-touch-area .get-in-touch-single .icon {
     width: 80px;
     height: 80px;
     line-height: 80px;
     text-align: center;
     border-radius: 8px;
     background-color: #101c3d 
}
 .contact-get-in-touch-area .get-in-touch-single .content h4 {
     color: #fff;
     font-size: 22px;
     font-weight: 700;
     font-style: normal;
     letter-spacing: -0.73px;
     margin-top: 35px;
     margin-bottom: 25px 
}
 .contact-get-in-touch-area .get-in-touch-single .content p {
     color: #687497;
     font-size: 18px;
     font-weight: 400;
     font-style: normal;
     letter-spacing: -0.59px;
     line-height: 30px 
}
 .contact-get-in-touch-area .get-in-touch-single .content a {
     text-decoration: none;
     font-weight: 700;
     color: #1D156B 
}
 .contact-us-supp-btn .btn {
     width: 100%;
     height: 60px;
     border-radius: 6px;
     background-color: #1D156B;
     color: #fff;
     font-size: 16px;
     font-weight: 700;
     font-style: normal;
     letter-spacing: -0.53px;
     line-height: normal;
     margin-top: 30px 
}
 @media(min-width:576px) {
     .contact-us-supp-btn .btn {
         width: 180px 
    }
}
 @media(min-width:768px) {
     .contact-us-supp-btn .btn {
         margin-top: 60px 
    }
}
 
</style>