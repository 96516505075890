<template>
<section>
        <!-- <form id="AssureContactForm" class="contact-us-form" @submit.prevent="sendEmail"> -->
        <form id="AssureContactForm" class="contact-us-form" :model="form"  @submit.prevent="sendEmail" ref="contactForm">
              <!-- <div class="g-recaptcha" data-sitekey="6Le-g68bAAAAAK1jQogqJ2kOBFyVq6NuBQk0fJXu"></div> -->
              <div class="w-full mx-auto text-left" v-show="isOpen">
                <div class="flex flex-wrap -m-2">
                  <div class="md:w-1/2 p-2 w-full">
                    <div class="relative">
                      <label for="name" class="leading-7 text-sm text-gray-600">Name</label>
                      <input type="text" 
                        id="name"
                        v-model="name"
                        name="name" 
                        placeholder="Enter your first and last name"
                        class="form-control w-full bg-gray-200 bg-opacity-50 rounded border border-gray-300 focus:border-purple-950 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                    </div>
                  </div>
                  <div class="md:w-1/2 p-2 w-full">
                    <div class="relative">
                      <label for="email" class="leading-7 text-sm text-gray-600">Email</label>
                      <input type="email" 
                        id="email"
                        v-model="email"
                        name="email" 
                        placeholder="Enter your email"
                        class="form-control w-full bg-gray-200 bg-opacity-50 rounded border border-gray-300 focus:border-purple-950 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                    </div>
                  </div>
                  <div class="md:w-1/2 p-2 w-full">
                    <div class="relative">
                      <label for="company_name" class="leading-7 text-sm text-gray-600">Company Name</label>
                      <input type="text" 
                        id="company_name"
                        v-model="company_name" 
                        name="company_name" 
                        placeholder="Enter your company name"
                        class="form-control w-full bg-gray-200 bg-opacity-50 rounded border border-gray-300 focus:border-purple-950 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                    </div>
                  </div>
                  <div class="md:w-1/2 p-2 w-full">
                    <div class="relative">
                      <label for="phone" class="leading-7 text-sm text-gray-600">Phone</label>
                      <input 
                        type="tel" 
                        id="phone"
                        maxlength="14"
                        pattern=".{10,14}"
                        v-model="phone"
                        name="phone" 
                        placeholder="Enter your phone number"
                        class="form-control w-full bg-gray-200 bg-opacity-50 rounded border border-gray-300 focus:border-purple-950 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                        @input="formatPhone"
                        >
                    </div>
                  </div>
                  <div class="md:w-1/2 p-2 w-full">
                    
                      <spend-input v-model.number="company_spend"></spend-input>
                      <!-- <input 
                        type="text" 
                        id="company_spend"
                        v-model="company_spend"
                        :value="formatSpendInput()" 
                        name="company_spend"
                        
                        
                        placeholder="Enter your typical yearly travel spend"
                        class="form-control w-full bg-gray-200 bg-opacity-50 rounded border border-gray-300 focus:border-purple-950 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"> -->
                  
                    <!-- @blur="onTypingText" 
                        @focus="isInputActive = true" -->
                  </div>
                  <div class="p-2 w-full">
                    <div class="relative">
                      <label for="message" class="leading-7 text-sm text-gray-600">Message</label>
                      <textarea 
                        id="message"
                        v-model="message" 
                        name="message"
                        class="form-control w-full bg-gray-200 bg-opacity-50 rounded border border-gray-300 focus:border-purple-950 focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
                    </div>
                  </div>
                  <div class="p-2 mx-auto relative">
                      <vue-recaptcha ref="recaptcha"
                        @verify="onVerify" sitekey="6Le-g68bAAAAAK1jQogqJ2kOBFyVq6NuBQk0fJXu">
                      </vue-recaptcha>
                  </div>
                  <div class="p-2 mx-auto w-full relative">
                  <!-- :disabled="!email.length" -->
                    <button 
                      type="submit" 
                      @click="isOpen =! isOpen" 
                      :disabled="isDisabled"
                      :class="{ disabled: isDisabled }"
                      class="flex mx-auto text-white bg-purple-950 border-0 focus:outline-none hover:bg-purple-800 rounded-lg text-lg">
                      <!-- <span @click="isOpen =! isOpen" class="w-48 py-2">Send Message</span></button> -->
                      <span class="w-48 py-2">Send Message</span></button>
                      <!-- <input type="submit">  -->
                      <!-- Send Message
                    </input> -->
                    <!-- <input type="submit" value="Send" class="invisible flex mx-auto text-white bg-purple-950 border-0 py-2 px-8 focus:outline-none hover:bg-purple-800 rounded-lg text-lg"></button> -->
                  </div>
                </div>
              </div>
              <!-- <transition name="fade">
              <div class="border-gray-200 flex flex-col text-center w-full" v-show="!isOpen">
                <h2 class="text-yellow-950 sm:text-5xl text-2xl font-bold">Thanks for getting in touch!</h2>
									<p class="font-extrabold mb-8 text-xl">Expect a reply from an Assure Travel Consultant very soon.</p>
              </div>
              </transition> -->

              <transition name="modal">
                <div id="modal-template" class="modal-mask" v-show="!isOpen">
                  <div class="modal-wrapper">
                    <div class="w-full md:w-1/2 mx-auto flex flex-col mb-16 justify-center items-center text-center bg-white border-2 shadow-lg rounded-lg border-gray-200 border-opacity-50 p-8 h-96">
                      <div class="modal-header">
                        <h2 class="text-purple-950 sm:text-5xl text-2xl font-bold">Thanks for getting in touch!</h2>
                      </div>
                      <div class="modal-body">
                        <p class="mb-8 text-xl">Expect a reply from an Assure Travel Consultant very soon.</p>
                      </div>
                      <div class="modal-footer">
                        <slot name="footer">
                          <button class="bg-purple-950 text-white active:bg-purple-950 font-bold uppercase text-xs px-4 py-2 rounded-md shadow hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" 
                          @click="isOpen =! isOpen">
                            OK
                          </button>
                        </slot>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </form>
</section>
</template>
<script>
import emailjs from 'emailjs-com';
import VueRecaptcha from 'vue-recaptcha';
import SpendInput from '@/components/SpendInput.vue';

export default {
        name: 'ContactForm',
        data() {
            return {
            name:           '',
            email:          '',
            company_name:   '',
            phone:          '',
            company_spend:  0,
            message:        '',
            isOpen:         true,
            form: {   
              robot:          false
            }
            }
        },
      computed: {
      isDisabled () {
        if (this.name.length > 1 && this.email.length > 4 && this.company_name.length > 2 && this.phone.length > 9 && this.form.robot == true) {
          return false;
        } else {
          return true;
        }
      }
    },
      methods: {
        formatPhone() {
            var x = this.phone
                .replace(/\D/g, "")
                .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.phone = !x[2] ? x[1]
                : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
            },
        sendEmail(e) {
          if (this.form.robot){
          try {
            emailjs.sendForm('service_jc0oc0d', 'template_ccsj5am', e.target, 'user_LLFN61yrORT6uCl1TucqR', {
              name: this.name,
              email: this.email,
              company_name: this.company_name,
              phone: this.phone,
              company_spend: this.company_spend,
              message: this.message,                
            })
            console.log('it works!!!')
             
            // this.isOpen = 'false'

      } catch(error) {
          console.log({error})
      }
      // Reset form field
      this.name = ''
      this.email = ''
      this.company_name = ''
      this.phone = ''
      this.company_spend = 0
      this.message = ''
      // this.isOpen = 'false'
        }},
        onVerify: function (response) {
          if (response) this.form.robot = true;
        }
    },
    components: {
    'vue-recaptcha': VueRecaptcha,
    SpendInput
    }
}
</script>
<style>
  .box-shadow-custom {
    box-shadow:
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 100px 80px rgba(0, 0, 0, 0.12);
    min-height: 200px;
    width: 65vw;
    margin: 100px auto;
    background: white;
    border-radius: 5px;
  }
/* Transition styles */
 .fade-enter-active, .fade-leave-active {
     transition: opacity 0.5s ease;
}
 .fade-enter-from, .fade-leave-to {
     opacity: 0;
}
  .contact-us-form {
     box-shadow: 0 -20px 79px rgba(0, 0, 0, 0.08);
     border-radius: 15px;
     background-color: #fff;
     padding: 33px 30px 40px 30px 
}
 @media(min-width:576px) {
     .contact-us-form {
         padding: 55px 50px 55px 50px 
    }
}
 @media(min-width:768px) {
     .contact-us-form {
         padding: 55px 75px 55px 75px 
    }
}
 .contact-us-form label {
     color: #1D156B;
     font-size: 16px;
     font-weight: 500;
     font-style: normal;
     letter-spacing: -0.6px;
     line-height: normal;
     margin-bottom: 20px 
}
 .contact-us-form .form-control::-moz-placeholder {
     color: #cbd5e0;
     opacity: 1 
}
 .contact-us-form .form-control:-ms-input-placeholder {
     color: #cbd5e0;
     opacity: 1 
}
 .contact-us-form .form-control::placeholder {
     color: #cbd5e0;
     opacity: 1 
}
 .contact-us-form .form-control {
     color: #81838c;
     font-size: 16px;
     font-weight: 400;
     font-style: normal;
     letter-spacing: -0.53px;
     line-height: normal;
     height: 50px;
     border-radius: 5px;
     border: 1px solid #eaeced;
     background-color: #f7fafc;
     margin-bottom: 28px;
     padding-left: 15px 
}
 .contact-us-form textarea {
     height: 220px !important;
     padding-top: 15px 
}
 .contact-us-form .btn {
     width: 100%;
     height: 60px;
     border-radius: 6px;
     background-color: #1D156B;
     color: #fff;
     font-size: 16px;
     font-weight: 700;
     font-style: normal;
     letter-spacing: -0.53px;
     line-height: normal;
     text-align: center 
}

.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, 0.5);
     display: table;
     transition: opacity 0.3s ease;
}
 .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
}
 .modal-container {
     width: 300px;
     margin: 0px auto;
     padding: 20px 30px;
     background-color: #fff;
     border-radius: 2px;
     box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
     transition: all 0.3s ease;
     font-family: Helvetica, Arial, sans-serif;
}
 .modal-header h3 {
     margin-top: 0;
     color: #42b983;
}
 .modal-body {
     margin: 20px 0;
}
 .modal-default-button {
     float: right;
}
/* * The following styles are auto-applied to elements with * transition="modal" when their visibility is toggled * by Vue.js. * * You can easily play with the modal transition by editing * these styles. */
 .modal-enter {
     opacity: 0;
}
 .modal-leave-active {
     opacity: 0;
}
 .modal-enter .modal-container, .modal-leave-active .modal-container {
     -webkit-transform: scale(1.1);
     transform: scale(1.1);
}

button.disabled{
cursor: not-allowed;
display:none;
}
.disabled:hover::after {
    content: 'Please make sure all required fields are filled out';
    display: block;
    width: 100%;
    text-align: center;
    background-color: white;
    color: red;
    position: absolute;
    bottom: -20px;
    left: 0;
}
</style>
