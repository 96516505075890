<template>
     <div class="relative">
                      <label for="company_spend" class="leading-7 text-sm text-gray-600">Travel Spend (Air, Car &
                        Hotel)</label>
            <input 
                class="form-control w-full bg-gray-200 bg-opacity-50 rounded border border-gray-300 focus:border-purple-950 focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                type="text" v-model="displayValue" @blur="isInputActive = false" @focus="isInputActive = true"/>
     </div>
</template>

<script>
export default {
    name:"SpendInput",
    props: ["value"],
        data() {
            return {
                isInputActive: false
            }
},
    computed: {
         displayValue: {
            get: function() {
                if (this.isInputActive) {
                    // Cursor is inside the input field. unformat display value for user
                    return this.value.toString()
                } else {
                    // User is not modifying now. Format display value for user interface
                    return "$ " + this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                }
            },
            set: function(modifiedValue) {
                // Recalculate value after ignoring "$" and "," in user input
                let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""))
                // Ensure that it is not NaN
                if (isNaN(newValue)) {
                    newValue = 0
                }
                // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
                // $emit the event so that parent component gets it
                this.$emit('input', newValue)
            }
        }
    }
};
</script>
<style scoped>
    .contact-us-form label {
     color: #1D156B;
     font-size: 16px;
     font-weight: 500;
     font-style: normal;
     letter-spacing: -0.6px;
     line-height: normal;
     margin-bottom: 20px 
}
 .contact-us-form .form-control::-moz-placeholder {
     color: #cbd5e0;
     opacity: 1 
}
 .contact-us-form .form-control:-ms-input-placeholder {
     color: #cbd5e0;
     opacity: 1 
}
 .contact-us-form .form-control::placeholder {
     color: #cbd5e0;
     opacity: 1 
}
 .contact-us-form .form-control {
     color: #81838c;
     font-size: 16px;
     font-weight: 400;
     font-style: normal;
     letter-spacing: -0.53px;
     line-height: normal;
     height: 50px;
     border-radius: 5px;
     border: 1px solid #eaeced;
     background-color: #f7fafc;
     margin-bottom: 28px;
     padding-left: 15px 
}
</style>
